import React, { useState, useEffect, useRef } from 'react'
import { useStaticQuery, graphql } from "gatsby"
// import PropTypes from 'prop-types'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Transition } from "@headlessui/react";
import { modal, modalInner, media, modalLink, close, popupModal, popup as actualPopup, cornerModal, straithsModal, modalContent } from '../styles/modal.module.scss'
import { Caption } from './Image';
import useOnClickOutside from '../utils/useOnClickOutside';
import useTimeout from '../utils/useTimeout'
import useCookie from '../utils/useCookie';
// import useScrollPosition from '../utils/useScrollPosition';
// import Loading from '../svg/Loading';
// import { straithHeader } from '../styles/header.module.scss'
// import Button from './Button';

const Modal = props => {

    const { className, image, children, title, useLink = false } = props;

    const [modalIsOpen, modalSetIsOpen ] = useState(false);

    // const toggleImage = () => modalSetIsOpen(!modalIsOpen);
    const toggleImage = () => modalSetIsOpen((modalIsOpen) => !modalIsOpen)

    const ref = useRef();

    let modalLinkClassName = modalLink;
    if ( className ) modalLinkClassName = [modalLink, className].join(" ");

    let modalPopupClassName = modal;

    useEffect(() => {
        const checkIfClickedOutside = e => {
    
          if (modalIsOpen && ref.current && !ref.current.contains(e.target)) {

            modalSetIsOpen(false)
    
            } else if ( modalIsOpen && ref.current && e.target.tagName !== 'IMG' ) {
                modalSetIsOpen(false)
            }

        }
    
        document.addEventListener("mousedown", checkIfClickedOutside)
    
    
        return () => {
    
          // Cleanup the event listener
    
          document.removeEventListener("mousedown", checkIfClickedOutside)
    
        }
    
      }, [modalIsOpen])

      useEffect(() => {

        // const header = document.querySelector(`.${straithHeader}`)

        // const getIndex = getComputedStyle(document.documentElement).getPropertyValue('--header-index');
        // const headerIndex = parseInt(getIndex)

        if (modalIsOpen) {
            document.documentElement.style.overflow = 'hidden'
            // header.style.zIndex = '1000'
            document.documentElement.style.setProperty('--header-index', 1000);
        } else {
            document.documentElement.style.overflow = 'unset'
            // header.style.zIndex = 1500
            document.documentElement.style.setProperty('--header-index', 1500);
        }
        
        return () => {
            document.documentElement.style.overflow = 'unset'
        }
        
     }, [modalIsOpen]);
      

    //   useEffect(() => {
    //     modalIsOpen ? (
    //         document.documentElement.style.overflow = 'hidden'
    //     ) : (
    //         document.documentElement.style.overflow = 'unset'
    //     )
        
    //     return () => {
    //         document.documentElement.style.overflow = 'unset'
    //     }
        
    //  }, [modalIsOpen]);

    if ( !image && !children && useLink === false ) return null;  

    return (<>

        <div className={modalLinkClassName} onClick={toggleImage}  onKeyDown={toggleImage} role={`button`} tabIndex={`0`} title={title ? title : null}>
            {/* { image ? (
                <GatsbyImage
                image={getImage(image?.localFile)}
                alt={image?.altText || `Image`}
                />
            ) : children ? (
                children
            ) : (
                null
            ) } */}

            {useLink === true ? (
                props.linkTitle
            ) : (
                <>
                    { image ? (
                        <GatsbyImage
                        image={getImage(image?.localFile)}
                        alt={image?.altText || `Image`}
                        />
                    ) : children ? (
                        children
                    ) : (
                        null
                    ) }
                </>
            ) }
        </div>
        {props.caption && <Caption>{props.caption}</Caption>}

        <Transition
                show={modalIsOpen}
                enter="transition duration-100 ease-out"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition duration-150 ease-out"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className={modalPopupClassName}
                >
                <div className={modalInner}>
                    <Transition.Child
                        enter={`transition ease-in-out duration-300 transform`}
                        enterFrom={`transform scale-90 opacity-0`}
                        enterTo={`transform scale-100 opacity-100`}
                        leave={`transition duration-150 ease-out`}
                        leaveFrom={`transform scale-100 opacity-100`}
                        leaveTo={`transform scale-90 opacity-0`}
                        className={media}
                        ref={ref}
                    >
                        { image ? (
                            <GatsbyImage
                            image={getImage(image?.localFile)}
                            alt={image?.altText || `Image`}
                            />
                        ) : children ? (
                            children
                        ) : (
                            null
                        ) }

                    {props.caption && <Caption className={`!text-white opacity-75 !text-xs`} inline={false}>{props.caption}</Caption>}
                    </Transition.Child>
                    {/* {loader ? <Loading className={`!absolute inset-0 m-auto -z-[5]`} fill={`var(--color-light)`} /> : null} */}
                </div>
                <button className={close}><span>&nbsp;</span></button>
        </Transition>
        
    </>)
}

export default Modal;


const Popup = ( {className, children, header, content, backgroundImage, delay = 5000, keyName, expiryDays} ) => {

    // const [hasShown, setHasShown] = useLocalStorage(initialValue, false)
    // const [hasShown, setHasShown] = useSessionStorage(initialValue, false)
    const [hasShown, setHasShown] = useCookie(keyName, false)
  
    const [isVisible, popupIsVisible] = React.useState(false)

    // const pos = useScrollPosition();
  
    const show = () => !hasShown && popupIsVisible(true)
  
    useTimeout(show, delay);
  
    const modalRef = React.useRef()
  
    // useOnClickOutside(
    //   modalRef, () => popupIsVisible(false));
    // const handleOutsideClick = () => {
    //     if (!hasShown) {
    //       popupIsVisible(false)
    //       // setHasShown(true)
    //       setHasShown(true, {
    //           days: 7,
    //           SameSite: 'Strict',
    //           Secure: true
    //         })
    //     }
    //   }
  
    const handleOutsideClick = () => {
      if (!hasShown) {
        popupIsVisible(false)
        setHasShown(true, {
            days: expiryDays,
            SameSite: 'Strict',
            Secure: true
          })
      }
    }
  
    useOnClickOutside(
      modalRef,
      handleOutsideClick
      );
    
      React.useEffect(() => {
        isVisible ? (
            document.documentElement.style.overflow = 'hidden'
        ) : (
            document.documentElement.style.overflow = 'unset'
        )
     }, [isVisible]);

     // if the popup IS in FACT visible, that means it has shown 
    //  React.useEffect(() => {
    //     isVisible && setHasShown(true, {
    //         days: expiryDays,
    //         SameSite: 'Strict',
    //         Secure: true
    //       })
    //  }, [isVisible, setHasShown, expiryDays])
      
    const popupClassName = [modal, popupModal].join(" ")

    // /* clear storage test */

    // const resetStorage = () => hasShown && popupIsVisible(true)

    // useTimeout(resetStorage, 60000);

    // /* END OF clear storage test */

    let innerStyle = null;
    if (backgroundImage) {
        innerStyle = {
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'cover',
            backgroundRepeat: `no-repear`,
            border: `none`
        }
    }
  
    return (
      <Transition
      show={isVisible}
      enter="transition duration-300 ease-out"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition duration-150 ease-out"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className={className ? [popupClassName, className].join(" ") : popupClassName}
      >
      <div className={modalInner} data-image={backgroundImage ? 'true' : 'false'}>
          <Transition.Child
              enter={`transition ease-in-out duration-500 transform`}
              enterFrom={`transform scale-90 opacity-0`}
              enterTo={`transform scale-100 opacity-100`}
              leave={`transition duration-150 ease-out`}
              leaveFrom={`transform scale-100 opacity-100`}
              leaveTo={`transform scale-90 opacity-0`}
              className={media}
              style={{...innerStyle}} 
          >
              <div className={actualPopup} ref={modalRef}>
                    {header && <h3>{header}</h3>}
                    {content && <div dangerouslySetInnerHTML={{__html: content}} />}
                    {children && children}

                </div>

          </Transition.Child>

      </div>
      
      <button onClick={() => popupIsVisible(false)} className={close}><span>&nbsp;</span></button>
</Transition>


    )
}

Popup.defaultProps = {
    header: ``,
    keyName: `popupShow`,
    expiryDays: 14
}

Modal.Popup = Popup;

export const CornerModal = props => {

    const { children, className, keyName} = props;

    const [modalIsOpen, modalSetIsOpen] = useState(false);
    const [hasShown, setHasShown] = useCookie(keyName, false)

    const showBox = () => !hasShown && modalSetIsOpen(true)
  
    useTimeout(showBox, 3000);

    const handleClick = () => {
        if (!hasShown) {
            modalSetIsOpen(false)
          setHasShown(true, {
              days: 100,
              SameSite: 'Strict',
              Secure: true
            })
        }
      }

    return (
        <Transition
        show={modalIsOpen}
        enter={`transition ease-in-out duration-500 transform`}
        enterFrom="translate-y-1/2 opacity-0"
        enterTo="translate-y-0 opacity-100"
        leave="transition ease-in-out duration-300 transform"
        leaveFrom="translate-y-0 opacity-100"
        leaveTo="translate-y-1/2 opacity-0"
        className={className ? [cornerModal, className].join(" ") : cornerModal}
      >
        <div className={modalInner}>
            {children && children}
            {/* <Button.Group>
                <Button as={`button`} onClick={handleClick}>Sounds Good</Button>
                <Button href={`https://html.com/resources/cookies-ultimate-guide/`} variation={`outline`} title={`Learn More About Cookies`}>Learn More</Button>
            </Button.Group> */}
            <div>
                <button onClick={handleClick}>Sounds Good</button>
                <button href={`https://html.com/resources/cookies-ultimate-guide/`} title={`Learn More About Cookies`}>Learn More</button>
            </div>
        </div>
        <button className={close} onClick={handleClick}><span>&nbsp;</span></button>
    </Transition>
    )
}

CornerModal.defaultProps = {
    keyName: `cookiesAccept`
}

const StraithsPopup = () => {
    const data = useStaticQuery(graphql`
    query SfcModalQuery {
        wp {
          sfcLayoutSettingsGroupSettings {
            sfcEnablePopup
            sfcPopupContent
            sfcPopupKey
            sfcPopupSubject
            sfcPopupTitle
          }
        }
      }
  `);

  const {wp: { sfcLayoutSettingsGroupSettings: { sfcEnablePopup: enable, sfcPopupTitle: title, sfcPopupContent: content, sfcPopupKey: key, sfcPopupSubject: subject} }} = data;

  if (!enable) return;
  else if (content === null) return;

  return (
    <Popup className={straithsModal} keyName={key ? key : 'sfc-global-notice'}>
        <div className={modalContent}>
            {title ? <h3>{title}</h3> : null}
            {subject ? <div className={`text-6xl uppercase font-bold !leading-[1.0] mb-gap text-secondary-default`}>{subject}</div> : null}
            <div dangerouslySetInnerHTML={{__html: content}} />
        </div>
        
    </Popup>
  )
}

Modal.StraithsPopup = StraithsPopup;