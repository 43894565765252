// extracted by mini-css-extract-plugin
export var close = "modal-module--close--adbb4";
export var cornerModal = "modal-module--corner-modal--d7f6e";
export var media = "modal-module--media--192a3";
export var modal = "modal-module--modal--a35cf";
export var modalContent = "modal-module--modal-content--407a2";
export var modalInner = "modal-module--modal-inner--fec16";
export var modalLink = "modal-module--modal-link--cabdf";
export var popup = "modal-module--popup--b4f87";
export var popupModal = "modal-module--popup-modal--f8511";
export var straithsModal = "modal-module--straiths-modal--7a79c";
export var video = "modal-module--video--7925c";