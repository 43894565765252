import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Modal from './Modal'

export const Caption = ({className, children, inline = true}) => {

    const baseClassName = `lg:text-xxs md:text-xs text-sm font-secondary font-medium !mt-3 md:!mt-2.5`;
    let captionClassName = baseClassName;
    if (inline !== false) captionClassName = [baseClassName, 'md:px-2 md:pr-3 lg:pr-4'].join(" ");

    return <div style={{color: 'currentColor', opacity: '0.5'}} className={className ? [captionClassName, className].join(" ") : captionClassName}>{children}</div>
}

Caption.propTypes = {
    children: PropTypes.node.isRequired
}

const Image = ( {as: Component, image, children, caption, ...imageRest} ) => {
    return (<>
        <Component>

            {image ? (
                <GatsbyImage 
                    image={getImage(image)}
                    {...imageRest}
                />
            ) : children ? (
                children
            ) : (
                ''
            )}
            {caption && <Caption>{caption}</Caption>}
        </Component>
        
    </>)
}

Image.propTypes = {
    caption: PropTypes.string,
    children: PropTypes.node
}

Image.defaultProps = {
    as: Fragment
}

const ModalImage = ( {image, children, caption, title, ...imageRest} ) => {

    return (
        <Modal caption={caption ? caption : null} title={title ? title : null}>
            {image ? (
                <GatsbyImage 
                    image={getImage(image)}
                    {...imageRest}
                />
            ) : children ? (
                children
            ) : (
                ''
            )}
        </Modal>
    )
}

ModalImage.propTypes = {
    caption: PropTypes.string,
    children: PropTypes.node
}

Image.Modal = ModalImage;

export default Image 
