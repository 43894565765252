module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"straiths-fine-clothing","short_name":"straiths","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/images/sfc-favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f9a147da6be0a05e47f565cf6178b5f7"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"http://localhost/sfc-wp/graphql","schema":{"perPage":10,"requestConcurrency":4,"previewRequestConcurrency":2,"timeout":120000,"circularQueryLimit":4,"queryDepth":15,"typePrefix":"Wp"},"type":{"MediaItem":{"localFile":{"requestConcurrency":1,"maxFileSizeBytes":100000000,"excludeByMimeTypes":[]},"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"PaColor":{"exclude":true},"PaSize":{"exclude":true},"Product":{"limit":10},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-B30TKCMFR7","G-DW2G7KEVPK","GT-WRHL523"],"pluginConfig":{"head":false,"delayOnRouteUpdate":0}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
