/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it

const React = require("react")
const Modal = require("./src/components/Modal").default

export const wrapPageElement = ({ element }) => {
      
    return (
    <>
      <Modal.StraithsPopup />
      {element}
    </>
    )
}

// export const wrapPageElement = ({ element }) => {
      
//     return (
//     <>
//       <Modal.Popup keyName={`sfc-early-summer-sale-2024`}
//        >
//         <div className={`grid grid-cols-1 gap-gap text-center md:!-m-gap uppercase tracking-[3px]`}>
//           <h3 className={`!text-4xl md:!text-5xl lg:!text-6xl uppercase !tracking-widest !-mb-2.5`}>Sale</h3>
//           <span>Outwear blowout</span>
//           <span className={`md:text-2xl lg:text-[2.15rem] text-xl`}>Up to <strong>70% Off</strong></span>
//           <div className={`grid grid-cols-1 gap-0 -space-y-0.5`}>
//             <span>Overcoats</span>
//             <span>Leather Jackets</span>
//             <span>Sweaters</span>
//             <span>Vests</span>
//             <span>& More</span>
//           </div>
//           <span>In-store only</span>
//           <span className={`normal-case tracking-wide`}>Starting <strong>Monday, March 4th</strong></span>
//         </div>
        
//        </Modal.Popup>
//       {element}
//     </>
//     )
// }

// export const shouldUpdateScroll = ({
//     routerProps: { location },
//     getSavedScrollPosition
//   }) => {
//     // transition duration from `layout.js` * 1000 to get time in ms
//     // * 2 for exit + enter animation
//     const TRANSITION_DELAY = 0.3 * 1000 * 2
  
//     // if it's a "normal" route
//     if (location.action === "PUSH") {
//       window.setTimeout(() => window.scrollTo({
//         top: 0,
//         behavior: 'smooth',
//       }), TRANSITION_DELAY)
//     }
  
//     // if we used the browser's forwards or back button
//     else {
//       const savedPosition = getSavedScrollPosition(location) || [0, 0]
  
//       window.setTimeout(() => window.scrollTo(...savedPosition), TRANSITION_DELAY)
//     }
  
//     return false
// }
